import { useMemo, useRef } from "react";
import { Pagination as GPagination } from "grommet";
import debounce from "lodash.debounce";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";

export default function Pagination({ onChange, ...rest }) {
  const large = useIsLarge();
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  const change = useMemo(
    () =>
      debounce(({ page }) => onChangeRef.current(page), 400, {
        leading: true,
      }),
    []
  );

  return (
    <GPagination
      onChange={change}
      size="medium"
      alignSelf="center"
      numberMiddlePages={large ? 6 : 3}
      overflow={{ horizontal: "auto" }}
      {...rest}
    />
  );
}
