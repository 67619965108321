import { seoDescription } from "components/seo";

export const descriptionByType = {
  allTime: seoDescription, //"All the coins, sorted by most votes all time.",
  today:
    "The most voted coins for today, each day the most voted coin will be promoted for the next 24h!",
  recent:
    "Coins sorted by the date they got added to Coinscope, add your token and start voting!",
  presale: "Coins in presale phase",
};

export const titleByType = {
  allTime: "All Time Best",
  today: "Today's best",
  recent: "New Coins",
  presale: "Presale phase",
};
