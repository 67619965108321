import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import RadioGroup, { RadioButton } from "components/radioGroup";

export default function TypePicker({ value, ...rest }) {
  const large = useIsLarge();

  return (
    <RadioGroup aria-label="Coin Navigation" {...rest}>
      <RadioButton
        href="/alltime"
        enabled={value === "allTime"}
        label="All Time"
      />
      <RadioButton
        href="/"
        enabled={value === "today"}
        label={large ? "Today's best" : "Today's"}
      />
      <RadioButton href="/recent" enabled={value === "recent"} label="New" />
      <RadioButton
        href="/presale"
        enabled={value === "presale"}
        label="Presale"
      />
    </RadioGroup>
  );
}
