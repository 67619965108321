import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import { Box } from "grommet";
import dynamic from "next/dynamic";

const Desktop = dynamic(() => import("./desktop"));
const Mobile = dynamic(() => import("./mobile"));

export default function CoinTable(props) {
  const large = useIsLarge();

  return (
    <Box height={{ min: `${props.slugs.length * 72}px` }}>
      {large ? <Desktop {...props} /> : <Mobile {...props} />}
    </Box>
  );
}
