import dynamic from "next/dynamic";
import { Box } from "grommet";
import Carousel from "@components/banners/carousel";
import CoinzillaInjection from "@components/coinzilla";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import config from "coinscope-config";
import { wideBannerAspect, bannerAspect } from "@components/banners/helpers";
import Aspect from "@components/aspect";

const { wideBannerWidth, bannerWidth } = config;

const WideBanner = dynamic(() => import("components/banners/wide"), {
  ssr: false,
});

export default function Banners({ bannerSlugs, wideBannerSlugs }) {
  const large = useIsLarge();

  const aspect = large ? wideBannerAspect : bannerAspect;
  const pad = large ? 16 : 6;
  const width = large ? wideBannerWidth : bannerWidth;
  const length = wideBannerSlugs.length;
  const fullAspect = aspect * length + (1 / (width / pad)) * 100 * (length - 1);

  return (
    <Box
      pad={large ? "small" : "none"}
      gap="small"
      round="xxsmall"
      background={large ? "#283045" : ""}
    >
      {bannerSlugs.length > 0 && <Carousel slugs={bannerSlugs} />}
      {wideBannerSlugs.length > 0 && (
        <Aspect aspect={fullAspect}>
          <WideBanner slugs={wideBannerSlugs} />
        </Aspect>
      )}
      {wideBannerSlugs.length < 1 && <CoinzillaInjection />}
    </Box>
  );
}
