import dynamic from "next/dynamic";
import { useCallback } from "react";
import { Box } from "grommet";
import { useRouter } from "next/router";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import Layout from "@components/layout";
import Banners from "@components/banners";
import Pagination from "components/pagination";
import { pageSize } from "@organisms/coin/helpers";
import BadgesDropdown from "@organisms/badges/dropdown";
import NetworkDropdown from "@organisms/networks/dropdown";
import CoinList from "@organisms/coin/list";
import UserReminder from "@organisms/airdrop/userReminder";
import { usePageValue } from "@organisms/page/provider";
import getPage from "helpers/getPage";
import TypePicker from "./typePicker";
import { descriptionByType, titleByType } from "./helpers";

const PopupBanner = dynamic(() => import("components/banners/popup"), {
  ssr: false,
});
const BotHeader = dynamic(() =>
  import("@components/seo/botHeader").then((component) => component.Header)
);

const margeLarge = { top: "medium" };

export default function Landing(props) {
  const {
    bySlug,
    coinSlugs,
    promotedSlugs,
    bannerSlugs,
    wideBannerSlugs,
    popupBannerSlug,
    totalCoins,
    type,
  } = props;

  const bot = usePageValue("bot");
  const router = useRouter();
  const page = getPage(router.query.page);
  const badge = router.query.badge;
  const network = router.query.network;
  const description = descriptionByType[type];
  const large = useIsLarge();

  const pushRouter = ({
    nextPage = page,
    nextBadge = badge,
    nextNetwork = network,
  }) => {
    const q = [
      nextPage > 1 && `page=${nextPage}`,
      nextBadge && `badge=${nextBadge}`,
      nextNetwork && `network=${nextNetwork}`,
    ]
      .filter(Boolean)
      .join("&");

    router.push(`?${q}`, "", { scroll: false });
  };

  const onPageChange = useCallback(
    (page) =>
      pushRouter({
        nextPage: page,
        nextBadge: badge,
        nextNetwork: network,
      }),
    [network, badge]
  );

  var onBadgeChange = useCallback(
    (value) =>
      pushRouter({ nextPage: 1, nextBadge: value, nextNetwork: network }),
    [network]
  );

  const onNetworkChange = useCallback(
    (value) =>
      pushRouter({ nextPage: 1, nextBadge: badge, nextNetwork: value }),
    [badge]
  );

  return (
    <Layout
      seo={{
        title: titleByType[type],
        ...(router.pathname !== "/" && { description }),
      }}
      disableCashbackPopup={Boolean(popupBannerSlug)}
      coins={bySlug}
      bot={bot}
      {...props}
    >
      {bot && <BotHeader type={type} />}

      <UserReminder margin={{ bottom: "medium" }} />

      <Banners bannerSlugs={bannerSlugs} wideBannerSlugs={wideBannerSlugs} />
      {promotedSlugs.length > 0 && (
        <CoinList
          gaCategory="promotedList"
          promoted
          slugs={promotedSlugs}
          rank={false}
          margin={{ top: "large" }}
        />
      )}
      <Box margin={{ top: "large" }}>
        <Box
          direction={large ? "row" : "column"}
          align={large ? "end" : "center"}
          justify="between"
          overflow={{ horizontal: "auto" }}
          gap={large ? "small" : "large"}
        >
          <TypePicker value={type} />
          <Box
            direction="row"
            align="center"
            justify="between"
            overflow={{ horizontal: "auto" }}
            fill={large ? false : "horizontal"}
            gap="medium"
          >
            <BadgesDropdown value={badge} onChange={onBadgeChange} />
            <NetworkDropdown network={network} onChange={onNetworkChange} />
          </Box>
        </Box>
      </Box>
      <CoinList
        slugs={coinSlugs}
        showVotesToday={type === "today"}
        margin={margeLarge}
        firstIndex={(page - 1) * pageSize}
        navigate={type === "airdrop" ? "/airdrop" : ""}
        initialVisible={3}
        gaCategory={`landing${type}`}
      />
      {totalCoins > pageSize && (
        <Pagination
          page={page}
          onChange={onPageChange}
          step={pageSize}
          numberItems={totalCoins}
          margin={margeLarge}
        />
      )}
      {popupBannerSlug && <PopupBanner slug={popupBannerSlug} />}
    </Layout>
  );
}
