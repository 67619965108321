import { Anchor, Box, Heading, Avatar } from "grommet";
import { useCoin, useCoins } from "@organisms/coin/provider";
import Link from "next/link";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import { useFetchAirdropPending } from "@organisms/coin/actions";

function Airdrop({ slug, ...rest }) {
  const { name, icon } = useCoin(slug);

  return (
    <Link href={`/coin/${slug}/airdrop`} passHref legacyBehavior>
      <Anchor
        label={name}
        icon={<Avatar src={icon} size="small" />}
        {...rest}
      />
    </Link>
  );
}

export default function UserReminder(props) {
  const large = useIsLarge();
  let [slugs, loaded] = useFetchAirdropPending();

  slugs = useCoins(
    (state) =>
      loaded &&
      slugs.filter(
        (slug) =>
          !state[slug].voted &&
          state[slug].airdrop &&
          state[slug].airdrop.votes < state[slug].airdrop.totalVotes
      )
  );

  if (!loaded || slugs.length === 0) return null;

  return (
    <Box
      pad={{ horizontal: "medium", vertical: "small" }}
      round="xsmall"
      border={{ color: "status-warning" }}
      direction={large ? "row" : "column"}
      align="baseline"
      gap={large ? "none" : "medium"}
      justify="between"
      {...props}
    >
      <Heading level="3" margin="none">
        Your Airdrops are waiting for vote!
      </Heading>
      <Box direction={large ? "row" : "column"} wrap>
        {slugs.map((slug) => (
          <Airdrop key={slug} slug={slug} margin={large ? "small" : "none"} />
        ))}
      </Box>
    </Box>
  );
}
