import { Box, Button, ThemeContext } from "grommet";
import Link from "next/link";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import styled from "styled-components";

const StyledButton = styled(Button)`
  white-space: nowrap;
  border-radius: 1px;
  padding: 8px 23px;
`;

const Li = styled.li`
  flex: 1;
  text-align: center;
`;

export function RadioButton({ href, enabled, ...rest }) {
  const large = useIsLarge();
  const size = large ? "medium" : "small";

  return (
    <Li>
      <ThemeContext.Extend
        value={{ button: { border: { width: "0", color: "initial" } } }}
      >
        <Link href={href} scroll={false} passHref legacyBehavior>
          <StyledButton
            hoverIndicator="#2A3247"
            size={size}
            fill
            plain
            color={enabled ? "brand" : "dark-6"}
            primary={enabled}
            {...rest}
          />
        </Link>
      </ThemeContext.Extend>
    </Li>
  );
}

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;

  li {
    border-left: 1px solid ${({ theme }) => theme.global.colors.border.dark};
  }
  li:first-child {
    border-left: none;
  }
`;

export default function RadioGroup({ children, ...rest }) {
  const large = useIsLarge();

  return (
    <Box
      round="large"
      role="navigation"
      fill={large ? false : "horizontal"}
      basis="auto"
      flex={false}
      overflow={{ horizontal: "auto" }}
      alignSelf={large ? "start" : "center"}
      border
      {...rest}
    >
      <Ul>{children}</Ul>
    </Box>
  );
}
