import { Box } from "grommet";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import { isDev } from "@helpers/environment";
import { usePageValue } from "@organisms/page/provider";
import useInjectScript from "@hooks/useInjectScript";

const options = {
  onLoad: () => {
    window.coinzilla_display = window.coinzilla_display || [];
    const c_display_preferences = {};
    c_display_preferences.zone = "2361b732dd6e033207";
    c_display_preferences.width = "728";
    c_display_preferences.height = "90";
    window.coinzilla_display.push(c_display_preferences);
  },
};

export default function CoinzillaInjection() {
  const adBlockDetected = usePageValue("adBlockDetected");
  const large = useIsLarge();

  const enabled = !isDev() && !adBlockDetected;

  useInjectScript(
    enabled && "coinzilla-banner",
    "https://coinzillatag.com/lib/display.js",
    options
  );

  if (!enabled) return null;

  return (
    <Box
      height={{ min: large ? "90px" : "106px" }}
      className="coinzilla"
      data-zone="C-2361b732dd6e033207"
      alignSelf="center"
    />
  );
}
