import dynamic from "next/dynamic";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import { bannerAspect } from "components/banners/helpers";
import config from "coinscope-config";
import Aspect from "@components/aspect";

const { bannerWidth, bannerHeight } = config;

const Desktop = dynamic(() => import("./desktop"), { ssr: false });
const Mobile = dynamic(() => import("./mobile"), { ssr: false });

export default function Carousel({ slugs, ...rest }) {
  const large = useIsLarge();

  // const { bot, pwa } = usePage();
  // const empty = true || pwa || bot;

  if (large) {
    slugs =
      slugs.length < 3
        ? slugs.length < 2
          ? ["", ...slugs, ""]
          : [...slugs, ""]
        : slugs;

    const length = Math.min(slugs.length, 4);
    const pad = 16;

    return (
      <Aspect
        aspect={
          (1 / ((bannerWidth * length + pad * (length - 1)) / bannerHeight)) *
          100
        }
        {...rest}
      >
        <Desktop slugs={slugs} />
      </Aspect>
    );
  }

  return (
    <Aspect aspect={bannerAspect} {...rest}>
      <Mobile slugs={slugs} />
    </Aspect>
  );
}
