import { Box } from "grommet";
import styled from "styled-components";

export const Container = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: ${({ aspect }) => aspect}%;
`;

export const Content = styled(Box)`
  position: absolute;
  inset: 0;
`;

export default function Aspect({ children, ...rest }) {
  return (
    <Container {...rest}>
      <Content>{children}</Content>
    </Container>
  );
}
